import { IBusiness } from 'app/entities/business/business.model';
import { VerificationStatus } from 'app/entities/enumerations/verification-status.model';

export interface IVerification {
  id?: number;
  status?: VerificationStatus | null;
  business?: IBusiness | null;
}

export class Verification implements IVerification {
  constructor(public id?: number, public status?: VerificationStatus | null, public business?: IBusiness | null) {}
}

export function getVerificationIdentifier(verification: IVerification): number | undefined {
  return verification.id;
}
