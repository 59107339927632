import { IImage } from 'app/entities/image/image.model';

export interface ICategory {
  id?: number;
  name?: string | null;
  icone?: string | null;
  fr_name?: string | null;
  code?: string | null;
  keyword?: string | null;
  image?: IImage | null;
}

export class Category implements ICategory {
  constructor(
    public id?: number,
    public name?: string | null,
    public icone?: string | null,
    public fr_name?: string | null,
    public code?: string | null,
    public keyword?: string | null,
    public image?: IImage | null
  ) {}
}

export function getCategoryIdentifier(category: ICategory): number | undefined {
  return category.id;
}
