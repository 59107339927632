import * as dayjs from 'dayjs';
import { IBusiness } from 'app/entities/business/business.model';
import { IUser } from 'app/entities/user/user.model';
import { IImage } from 'app/entities/image/image.model';

export interface IComment {
  id?: number;
  date?: dayjs.Dayjs | null;
  text?: string;
  title?: string;
  blocked?: boolean | null;
  likeScore?: number | null;
  read?: boolean | null;
  business?: IBusiness;
  user?: IUser;
  images?: IImage[] | null;
}

export class Comment implements IComment {
  constructor(
    public id?: number,
    public date?: dayjs.Dayjs | null,
    public text?: string,
    public title?: string,
    public blocked?: boolean | null,
    public likeScore?: number | null,
    public read?: boolean | null,
    public business?: IBusiness,
    public user?: IUser,
    public images?: IImage[] | null
  ) {
    this.blocked = this.blocked ?? false;
    this.read = this.read ?? false;
  }
}

export function getCommentIdentifier(comment: IComment): number | undefined {
  return comment.id;
}
