export interface ILocation {
  id?: number;
  latitude?: number | null;
  longitude?: number | null;
  zoom?: number | null;
  address?: string | null;
}

export class Location implements ILocation {
  constructor(
    public id?: number,
    public latitude?: number | null,
    public longitude?: number | null,
    public zoom?: number | null,
    public address?: string | null
  ) {}
}

export function getLocationIdentifier(location: ILocation): number | undefined {
  return location.id;
}
