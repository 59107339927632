export interface ISection {
  id?: number;
  name?: string | null;
}

export class Section implements ISection {
  constructor(public id?: number, public name?: string | null) {}
}

export function getSectionIdentifier(section: ISection): number | undefined {
  return section.id;
}
