import { IActivity } from 'app/entities/activity/activity.model';
import { IUser } from 'app/entities/user/user.model';
import { IImage } from 'app/entities/image/image.model';
import { ILocation } from 'app/entities/location/location.model';

export interface IBusiness {
  id?: number;
  name?: string | null;
  website?: string | null;
  phoneNumber?: string | null;
  hoursOfOperation?: string | null;
  about?: string | null;
  youtube?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  rating?: number | null;
  email?: string | null;
  completeness?: number | null;
  whatsapp?: string | null;
  visits?: number | null;
  comments?: number | null;
  link?: string | null;
  blocked?: boolean | null;
  verified?: boolean | null;
  activities?: IActivity[] | null;
  owner?: IUser;
  logo?: IImage | null;
  images?: IImage[] | null;
  location?: ILocation | null;
}

export class Business implements IBusiness {
  constructor(
    public id?: number,
    public name?: string | null,
    public website?: string | null,
    public phoneNumber?: string | null,
    public hoursOfOperation?: string | null,
    public about?: string | null,
    public youtube?: string | null,
    public facebook?: string | null,
    public twitter?: string | null,
    public instagram?: string | null,
    public rating?: number | null,
    public email?: string | null,
    public completeness?: number | null,
    public whatsapp?: string | null,
    public visits?: number | null,
    public comments?: number | null,
    public link?: string | null,
    public blocked?: boolean | null,
    public verified?: boolean | null,
    public activities?: IActivity[] | null,
    public owner?: IUser,
    public logo?: IImage | null,
    public images?: IImage[] | null,
    public location?: ILocation | null
  ) {
    this.blocked = this.blocked ?? false;
    this.verified = this.verified ?? false;
  }
}

export function getBusinessIdentifier(business: IBusiness): number | undefined {
  return business.id;
}
