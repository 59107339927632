import { ICategory } from 'app/entities/category/category.model';

export interface IActivity {
  id?: number;
  name?: string | null;
  fr_name?: string | null;
  code?: string | null;
  keyword?: string | null;
  category?: ICategory | null;
}

export class Activity implements IActivity {
  constructor(
    public id?: number,
    public name?: string | null,
    public fr_name?: string | null,
    public code?: string | null,
    public keyword?: string | null,
    public category?: ICategory | null
  ) {}
}

export function getActivityIdentifier(activity: IActivity): number | undefined {
  return activity.id;
}
