export interface IImage {
  id?: number;
  contentsContentType?: string;
  contents?: string;
  description?: string | null;
  path?: string | null;
}

export class Image implements IImage {
  constructor(
    public id?: number,
    public contentsContentType?: string,
    public contents?: string,
    public description?: string | null,
    public path?: string | null
  ) {}
}

export function getImageIdentifier(image: IImage): number | undefined {
  return image.id;
}
