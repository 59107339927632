import { IUser } from 'app/entities/user/user.model';
import { DocumentType } from 'app/entities/enumerations/document-type.model';

export interface IDocument {
  id?: number;
  path?: string | null;
  description?: string | null;
  type?: DocumentType | null;
  owner?: IUser | null;
}

export class Document implements IDocument {
  constructor(
    public id?: number,
    public path?: string | null,
    public description?: string | null,
    public type?: DocumentType | null,
    public owner?: IUser | null
  ) {}
}

export function getDocumentIdentifier(document: IDocument): number | undefined {
  return document.id;
}
