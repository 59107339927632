import { Currency } from 'app/entities/enumerations/currency.model';
import { Period } from 'app/entities/enumerations/period.model';

export interface IFormula {
  id?: number;
  name?: string | null;
  duree?: string | null;
  price?: number | null;
  currency?: Currency | null;
  active?: boolean | null;
  period?: Period | null;
  stripeRef?: string | null;
}

export class Formula implements IFormula {
  constructor(
    public id?: number,
    public name?: string | null,
    public duree?: string | null,
    public price?: number | null,
    public currency?: Currency | null,
    public active?: boolean | null,
    public period?: Period | null,
    public stripeRef?: string | null
  ) {
    this.active = this.active ?? false;
  }
}

export function getFormulaIdentifier(formula: IFormula): number | undefined {
  return formula.id;
}
